<template>
  <div id="app" class="h-screen !select-none min-h-[500px]">
    <div v-if="isShow">
      <div class="close" v-if="$route.name != 'tiktok' && $route.name != 'start'" @click="close()">
        <img src="../common/close.png" />
      </div>
    </div>
    <!-- 音乐 -->
    <audio src="./assets/music4.mp3" preload="auto" loop="true" autoplay="true" id="music" ref="audio"></audio>
    <router-view></router-view>
  </div>
</template>

<script>
import { wxShare } from './api/taisui/taisui'
import uni from './uniapp.js'
import wx from 'weixin-js-sdk'
;(function () {
  if (typeof WeixinJSBridge == 'object' && typeof WeixinJSBridge.invoke == 'function') {
    handleFontSize()
  } else {
    if (document.addEventListener) {
      document.addEventListener('WeixinJSBridgeReady', handleFontSize, false)
    } else if (document.attachEvent) {
      document.attachEvent('WeixinJSBridgeReady', handleFontSize)
      document.attachEvent('onWeixinJSBridgeReady', handleFontSize)
    }
  }
  function handleFontSize() {
    // 设置网页字体为默认大小
    WeixinJSBridge.invoke('setFontSizeCallback', { fontSize: 0 })
    // 重写设置网页字体大小的事件
    WeixinJSBridge.on('menu:setfont', function () {
      WeixinJSBridge.invoke('setFontSizeCallback', { fontSize: 0 })
    })
  }
})()
export default {
  metaInfo: {
    title: '暖心太岁2023',
  },
  computed: {
    mute() {
      return this.$store.state.mute
    },
  },
  data() {
    return {
      isShow: false,
      isApp: true,
    }
  },

  watch: {
    mute(val) {
      if (val) {
        this.$refs.audio.pause()
      } else {
        this.$refs.audio.play()
      }
    },
  },
  // 解决安卓手机调出来软键盘，屏幕背景被挤压的问题
  created() {
    var ua = window.navigator.userAgent.toLowerCase()
    let that = this
    if (ua.match(/MicroMessenger/i) == 'micromessenger') {
      //微信环境下
      wx.miniProgram.getEnv(function (res) {
        if (res.miniprogram) {
          // 小程序环境下逻辑
          that.isApp = false
          that.isShow = true
          that.$store.state.isH5InWeapp = true
          console.log('isH5InWeapp', that.$store.state.isH5InWeapp)
          console.log('小程序环境下')
        } else {
          //非小程序环境下逻辑
          // this.isShow=true
          console.log('非小程序环境下')
        }
      })
    } else {
      that.isShow = true
      console.log('都bus')
    }

    // 获取当前可视区域的高度
    const height = document.documentElement.clientHeight
    let u = navigator.userAgent
    let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1
    if (isAndroid) {
      //这个是安卓操作系统
      window.onload = () => {
        document.getElementById('app').style.height = `${height}px`
        document.getElementById('app_2').style.height = `${height}px`
      }
    }
  },

  mounted() {
    this.autoPlay()
  },

  methods: {
    close() {
      if (this.isApp) {
        uni.webView.switchTab({ url: '/pages/zhili/zhili' })
      } else {
        wx.miniProgram.switchTab({ url: '/pages/zhili/zhili' })
      }
    },

    autoPlay() {
      let audio = this.$refs.audio

      if (audio.networkState === 0 || audio.networkState === 3) {
        // 网络断开
        audio.load()
      }

      audio.play()
      if (audio.networkState == 1) {
        document.removeEventListener('touchstart', this.autoPlay)
      } else {
        document.addEventListener('touchstart', this.autoPlay)
      }
    },
  },
}
</script>

<style lang="scss">
.close {
  position: absolute;
  top: 0.6rem;
  left: 0.5rem;
  width: 2rem;
  height: 2rem;
  z-index: 999;
  img {
    width: 2rem;
    height: 2rem;
    background-size: 100% 100%;
  }
}
.animation-delay-2000 {
  animation-delay: 2s !important;
}
.animation-delay-4000 {
  animation-delay: 4s !important;
}
#app {
  text-size-adjust: 100% !important;
  -webkit-text-size-adjust: 100% !important;
  -moz-text-size-adjust: 100% !important;
}
</style>
