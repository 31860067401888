<template>
    <TheBackground :show-footer="showFooter" @goDownPage="goDownPage()" @goUpPage="goUpPage()">
        <template slot="override">
            <transition name="rotate" mode="out-in">
                <div
                    class="pt-[18vw] short:pt-[5vw] se:pt-[2vw] w-full h-[160vw] max-h-[80%] long:h-[80%]"
                    v-if="phase === 1"
                    key="phase_1"
                >
                    <div
                        class="relative bg-center bg-no-repeat z-20 flex flex-col items-center justify-center h-full pt-[21vh] short:pt-[21vh] pb-[2vh] short:pb-[1.5vh] px-3"
                        style="background-size: 100% 100%"
                        :style="'background-image: url(' + boardImg + ')'"
                    >
                        <div class="relative flex flex-col items-center justify-start z-30 w-full h-full" v-if="taiSui">
                            <div class="flex flex-col items-center justify-start w-full overflow-y-scroll flex-grow">
                                <div class="">
                                    <img
                                        :src="taiSui.image"
                                        class="border-2 border-[#C79654] border-solid rounded-full shadow-md w-[7rem] h-[7rem] short:w-[6rem] short:h-[6rem] se:w-[5rem] se:h-[5rem] overflow-hidden"
                                    />
                                </div>

                                <div class="mt-[0.5rem] text-[1.06rem] leading-[1.06rem] font-bold">
                                    {{ taiSui.year }}太岁·{{ taiSui.name }}
                                </div>
                                <div class="mt-[0.4rem] text-[1rem] leading-[1rem] font-medium text-[#CB1A1B]">
                                    {{ taiSui.nick_Name }}的守护大将军
                                </div>
                                <div class="mt-[0.8rem] short:mt-[0.5rem] se:text-[0.6rem] px-[2rem]">
                                    {{ taiSui.story }}
                                </div>
                            </div>

                            <div
                                @click="phase = 2"
                                class="mt-[0.5rem] w-[8.78rem] h-[2.34rem] bg-contain bg-no-repeat flex-shrink-0 flex items-center justify-center tracking-tight text-[#CB1A1B] font-bold text-[0.95rem] se:text-[0.75rem]"
                                style="
                                    background-image: url('https://ttnzd.cn/static/v5/nxts-2022/nxts_benmingtaisui_text_btn.png');
                                "
                            >
                                {{ phaseButtonText[phase] }}
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    class="pt-[18vw] short:pt-[5vw] se:pt-[2vw] w-full h-[160vw] max-h-[80%] long:h-[80%]"
                    v-if="phase === 2"
                    key="phase_2"
                >
                    <div
                        class="relative bg-center bg-no-repeat z-20 flex flex-col items-center justify-center h-full pt-[21vh] short:pt-[21vh] pb-[2vh] short:pb-[1.5vh] px-3"
                        style="background-size: 100% 100%"
                        :style="'background-image: url(' + boardImg + ')'"
                    >
                        <div class="flex flex-col justify-start z-30 w-full h-full items-center" v-if="taiSui">
                            <div class="flex flex-col items-center justify-start w-full overflow-y-scroll flex-grow">
                                <div
                                    class="mt-[1.5rem] se:mt-[1rem] text-center text-[#CB1A1B] text-[1.13rem] leading-[1.75rem] font-bold"
                                >
                                    <div class="">与{{ taiSui.nick_Name }}</div>
                                    <div class="">同样得到{{ taiSui.year }}太岁</div>
                                    <div class="">庇佑的有</div>
                                </div>

                                <div class="mt-[1rem] flex flex-row mx-8 items-center justify-around self-stretch">
                                    <div
                                        class="w-1/3 flex flex-col items-stretch"
                                        v-for="(idol, index) in taiSui.stars"
                                        :key="index"
                                    >
                                        <div class="flex flex-col items-center justify-center">
                                            <div class="h-0 pb-[100%] bg-contain bg-no-repeat flex-shrink-0 bg-center">
                                                <img :src="idol.image" class="shadow-md rounded-full" />
                                            </div>
                                            <div class="text-center se:text-sm">{{ idol.name }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div
                                @click="phase = 1"
                                class="mt-[0.5rem] w-[8.78rem] h-[2.34rem] bg-contain bg-no-repeat flex-shrink-0 flex items-center justify-center tracking-tight text-[#CB1A1B] font-bold text-[0.95rem] se:text-[0.75rem]"
                                style="
                                    background-image: url('https://ttnzd.cn/static/v5/nxts-2022/nxts_benmingtaisui_text_btn.png');
                                "
                            >
                                {{ phaseButtonText[phase] }}
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </template>
    </TheBackground>
</template>

<script>
import TheBackground from '../../components/TheBackground'
import { meaning } from '../../api/taisui/taisui'
export default {
    name: 'TaiSui',
    components: {
        TheBackground: TheBackground,
    },
    data() {
        return {
            phase: 1,
            phaseButtonText: {
                1: '看看有谁和我一样',
                2: '返回我的本命太岁',
            },
            showFooter: true,
            boardImg: 'https://ttnzd.cn/static/v5/nxts-2022/nxts_benmingtaisui_text_bg.png',
            taiSui: null,
        }
    },
    created() {
        this.getMeaning()
        this.$wxShare('https://new-taisui.ttnzd.cn' + this.$route.path)
    },
    methods: {
        // 跳转页面
        goDownPage() {
            this.$router.push('/tai-sui/gatherInformation')
        },
        goUpPage() {
            this.$router.push('/tai-sui/tai-sui-details')
        },
        getMeaning: async function () {
            let url = this.$store.state.user.client_ID
            let data = this.$store.state.user
            let res = (await meaning(url, data).then()).data.data
            this.taiSui = res
            console.log(url, data, res, '--------------')
        },
    },
}
</script>

<style></style>
