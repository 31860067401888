<template>
    <TheBackground
        :showFooter="true"
        :max-board-height="80"
        board-img="https://ttnzd.cn/static/v5/nxts-2022/nxts_srxx_text_bg.png"
        @goDownPage="goDownPage()"
        @goUpPage="goUpPage()"
    >
        <!-- 遮罩层 -->
        <template v-slot:masks>
            <DecadeSelector
                @getAge="getAge"
                @getChineseZodiac="getChineseZodiac"
                :maskType="maskType"
                :maskValue="maskValue"
                v-if="decadeSelectorShow"
                @close="close()"
            />
            <ShowToast v-if="isShowToast" :showText="showText" />
        </template>
        <div class="w-full mx-[1rem] relative">
            <div class="text-[1.8rem] tracking-widest text-[#A80001] font-bold text-center">暖心太岁</div>
            <div class="text-[1.8rem] tracking-widest text-[#A80001] font-bold text-center">在线守护</div>
            <div
                class="mt-[1rem] mx-[3.5rem] h-[3.22rem] inputBg text-center text-white text-[1rem] font-normal leading-[3.22rem] special"
                @click="changeValue(index)"
                v-for="(item, index) in inputValue"
                :key="index"
                :class="[index === 'name' ? 'text-transparent' : '']"
            >
                {{ item }}{{ index == 'age' ? '年代' : '' }}
            </div>
            <input
                ref="input"
                @blur="onBlur()"
                @focus="focus()"
                maxlength="6"
                type="text"
                v-model="inputValue.name"
                placeholder="输入你的名字"
            />
        </div>
    </TheBackground>
</template>

<script>
import TheBackground from '../../components/TheBackground'
import DecadeSelector from '../../components/masks/DecadeSelector'
import ShowToast from '../../components/masks/ShowToast'
import { submitNews } from '../../api/taisui/taisui'
export default {
    components: {
        TheBackground,
        DecadeSelector,
        ShowToast,
    },
    data() {
        return {
            maskType: '',
            maskValue: '',
            years: [
                { id: '0', txt: '50' },
                { id: '1', txt: '60' },
                { id: '2', txt: '70' },
                { id: '3', txt: '80' },
                { id: '4', txt: '90' },
                { id: '5', txt: '00' },
                { id: '6', txt: '10' },
                { id: '7', txt: '20' },
            ],
            zodiacs: [
                { id: '0', txt: '鼠', isshow: 'true' },
                { id: '1', txt: '牛', isshow: 'true' },
                { id: '2', txt: '虎', isshow: 'true' },
                { id: '3', txt: '兔', isshow: 'true' },
                { id: '4', txt: '龙', isshow: 'true' },
                { id: '5', txt: '蛇', isshow: 'true' },
                { id: '6', txt: '马', isshow: 'true' },
                { id: '7', txt: '羊', isshow: 'true' },
                { id: '8', txt: '猴', isshow: 'true' },
                { id: '9', txt: '鸡', isshow: 'true' },
                { id: '10', txt: '狗', isshow: 'true' },
                { id: '11', txt: '猪', isshow: 'true' },
            ],
            inputValue: {
                name: '',
                age: '',
                chineseZodiac: '',
            },
            decadeSelectorShow: false, //遮罩层
            year: {
                first: '', //确定前三位
                last: '', //确定最后一位
            },
            yearsIndex: '',
            isFlag: {
                isName: true,
                isAge: true,
                isZodiacs: true,
            }, //确认有没有改值
            isShowToast: false,
            showText: '',
        }
    },
    created() {
        this.inputValue.name = this.$store.state.nick_Name
        this.inputValue.age = this.$store.state.age
        this.inputValue.chineseZodiac = this.$store.state.zodiac
    },
    mounted() {},
    destroyed() {
        this.isFlag = {
            isName: true,
            isAge: true,
            isZodiacs: true,
        }
    },
    methods: {
        // 跳转页面
        goDownPage() {
            this.$router.push('/tai-sui/start')
        },
        goUpPage: async function () {
            if (
                this.inputValue.name != '' &&
                this.inputValue.age != '我的出生' &&
                this.inputValue.chineseZodiac != '我的生肖'
            ) {
                // 没有修改过值
                if (this.isFlag.isName && this.isFlag.isAge && this.isFlag.isZodiacs) {
                    
                } else {
                    this.showText = ''
                    this.isShowToast = true
                    //修改过值
                    let data = {
                        type: 3,
                        zodiac: '虎',
                        client_ID: '',
                        sub_ID: '',
                        nick_Name: this.$store.state.nick_Name,
                        birth_Year: this.$store.state.birth_Year * 1,
                        agent: '',
                    }
                    let res = await submitNews(data)
                        .then((res) => {
                            // 处理数据
                            let user = res.data.data.user[0]
                            let newUser = {}
                            Object.keys(user).forEach((key) => {
                                let newValue = user[key]
                                if (key == 'birth_Year') {
                                    newValue = user[key] * 1
                                }
                                newUser[key] = newValue
                            })
                            newUser['type'] = 3
                            newUser['zodiac'] = '虎'
                            this.$store.commit('userAll', newUser)
                            this.isShowToast = false
                        })
                        .catch()
                }
                this.$router.push('/tai-sui/tai-sui')
            } else {
                console.log('qing')
                this.showText = '请输入完整信息'
                    this.isShowToast = true
                    setTimeout(() => {
                        this.isShowToast = false
                    }, 2000)
                
            }
        },
        focus() {
            this.isFlag.isName = false
        },
        onBlur() {
            this.$store.commit('nick_NameAll', this.inputValue.name)
            console.log(this.$store.state.nick_Name, '111111')
        },
        // 三个按钮对应的事件
        changeValue(index) {
            if (index == 'name') {
                this.isFlag.isName = false
                this.$refs.input.focus()
            }
            if (index == 'age') {
                this.isFlag.isAge = false
                this.maskType = 'age'
                this.decadeSelectorShow = true
                this.maskValue = this.years
            }
            if (index == 'chineseZodiac') {
                if (this.isFlag.isAge) {
                    this.showText = '请先选择你出生的年代'
                    this.isShowToast = true
                    setTimeout(() => {
                        this.isShowToast = false
                    }, 2000)
                } else {
                    this.isFlag.isZodiacs = false
                    this.maskType = 'chineseZodiac'
                    this.decadeSelectorShow = true
                    this.maskValue = this.zodiacs
                }
            }
        },
        getAge(item, index) {
            this.decadeSelectorShow = false
            this.inputValue.age = item.txt
            // 先还原
            this.zodiacs = [
                { id: '0', txt: '鼠', isshow: 'true' },
                { id: '1', txt: '牛', isshow: 'true' },
                { id: '2', txt: '虎', isshow: 'true' },
                { id: '3', txt: '兔', isshow: 'true' },
                { id: '4', txt: '龙', isshow: 'true' },
                { id: '5', txt: '蛇', isshow: 'true' },
                { id: '6', txt: '马', isshow: 'true' },
                { id: '7', txt: '羊', isshow: 'true' },
                { id: '8', txt: '猴', isshow: 'true' },
                { id: '9', txt: '鸡', isshow: 'true' },
                { id: '10', txt: '狗', isshow: 'true' },
                { id: '11', txt: '猪', isshow: 'true' },
            ]
            // 选择年代后改变生肖
            this.zodiacs[(index * 10 + 2 + 10) % 12].isshow = 'false'
            this.zodiacs[(index * 10 + 2 + 11) % 12].isshow = 'false'
            this.year.first = 1950 + index * 10
            this.yearsIndex = index
            this.$store.commit('ageAll', item.txt)
        },
        getChineseZodiac(item, index) {
            if (item.isshow == 'false') {
                console.log('11')
            } else {
                let scope = {
                    z: 0,
                    y: 0,
                }
                if (this.years[this.yearsIndex].txt == '00') {
                    scope.y = 5
                } else if (this.years[this.yearsIndex].txt == '10') {
                    scope.y = 6
                } else {
                    scope.y = parseInt(this.years[this.yearsIndex].txt) / 10 - 5
                }
                this.year.last = 1950 + scope.y * 10 + ((index - ((scope.y * 10 + 2) % 12) + 12) % 12)

                this.$store.commit('birth_YearAll', JSON.stringify(this.year.last))
                this.$store.commit('zodiacAll', item.txt)

                this.decadeSelectorShow = false
                this.inputValue.chineseZodiac = item.txt
            }
        },
        // 遮罩层关闭按钮
        close() {
            this.decadeSelectorShow = false
        },
    },
}
</script>

<style lang="scss" scoped>
.main {
    background: url('https://ttnzd.cn/static/v5/nxts-2022/nxts_srxx_text_bg.png') no-repeat;
    background-size: 100% 100%;
}
.inputBg {
    background: url('https://ttnzd.cn/static/v5/nxts-2022/nxts_srxx_btns.png') no-repeat;
    background-size: 100% 100%;
}
.back_btn {
    background: url('https://ttnzd.cn/static/v5/nxts-2022/nxts_srxx_btn2.png') no-repeat;
    background-size: 100% 100%;
}
.next_btn {
    background: url('https://ttnzd.cn/static/v5/nxts-2022/nxts_srxx_btn.png') no-repeat;
    background-size: 100% 100%;
}
input {
    position: absolute;
    top: 7.2rem;
    left: 50%;
    transform: translateX(-50%);
    border: none;
    outline: none;
    background-color: transparent;
    text-align: center;
    color: #ffffff;
}
input::-webkit-input-placeholder {
    color: #ffffff;
}
</style>
