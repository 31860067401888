import Vue from 'vue'
import VueRouter from 'vue-router'
import Start from '../views/tai-sui/Start.vue'
import Test from '../views/tai-sui/Test.vue'
import TaiSui from '../views/tai-sui/TaiSui.vue'
import TaiSuiDetails from '../views/tai-sui/TaiSuiDetails.vue'
import Fingerprint from '../views/tai-sui/Fingerprint.vue'
import TaiSuiSolver from '../views/tai-sui/TaiSuiSolver.vue'
import Home from '../views/Home.vue'
import GatherInformation from '../views/tai-sui/GatherInformation.vue'
import Tiktok from '../views/tai-sui/Tiktok.vue'
Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home,
        redirect: '/tai-sui/start',
    },

    // 开始
    {
        path: '/tai-sui',
        name: 'index',
        redirect: '/tai-sui/start',
        title: '暖心太岁2023',
    },
    {
        path: '/tai-sui/start',
        name: 'start',
        component: Start,
        title: '暖心太岁2023',
    },

    // 本命太岁
    {
        path: '/tai-sui/tai-sui',
        name: 'tai-sui',
        component: TaiSui,
    },

    // 太岁解释
    {
        path: '/tai-sui/tai-sui-details',
        name: 'tai-sui-details',
        component: TaiSuiDetails,
    },

    // 指纹
    {
        path: '/tai-sui/fingerprint',
        name: 'fingerprint',
        component: Fingerprint,
    },

    // 破解太岁
    {
        path: '/tai-sui/tai-sui-solver',
        name: 'tai-sui-solver',
        component: TaiSuiSolver,
    },
    {
        path: '/tai-sui/tiktok',
        name: 'tiktok',
        component: Tiktok,
    },
    {
        path: '/tai-sui/test',
        name: 'test',
        component: Test,
    },
    {
        path: '/tai-sui/gatherInformation',
        name: 'gatherInformation',
        component: GatherInformation,
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
})

export default router
