<template>
    <div class="absolute w-full h-screen bg-black/70 z-[100]"> 
        <div class="mt-[2.5rem] mx-[2rem] h-[20rem] main"></div>
        <div class="btn" @click="close()">关闭提示</div>
    </div>

</template>
<script>
export default {
    methods:{
        close(){
            this.$emit('close');
        }
    }
}
</script>
<style lang="scss" scoped>
.main{
    background: url("https://ttnzd.cn/static/v5/nxts-2022/fenxiang.png") no-repeat;
    background-size: 100% 100%;
    animation:goDown 0.6s ease-out 1 backwards;
}
@keyframes goDown {
    0%{
        transform:translateY(-15rem);
    }
}
.btn{
    position: fixed;
    bottom: 2rem;
    left: 50%;
    width: 15rem;
    text-align: center;
    padding: 1rem 0;
    transform: translateX(-50%);
    border: 0px solid #FFE4BA;
    background: linear-gradient(0deg, #D32211 0%, #E84B1A 100%);
    border-radius: 1rem;
    font-size: 1.06rem;
    font-weight: 500;
    color: #FFE4BA;
    opacity: 0;
    animation: onShow 0.5s 0.8s linear 1 forwards;
}
@keyframes onShow {
    100%{
        opacity: 1;
    }
}


</style>