<template>
  <div class="w-full h-full relative bg-[#C71A17]">
    <!-- 灯笼 -->
    <img
      class="absolute top-0 right-0 z-[999] w-[5.5rem] h-[7rem] rounded-full shadow-md"
      src="https://ttnzd.cn/static/v5/nxts-2022/nxts_srxx_denglong.png"
    />

    <!-- 返回按钮 -->
    <img
      @click="toIndex()"
      class="absolute top-[1rem] left-[1rem] z-[999] w-[2rem] h-[2rem] border border-yellow-300 border-solid rounded-full"
      src="https://ttnzd.cn/static/v5/nxts-2022/back.png"
    />

    <!-- 红色背景 -->
    <div class="absolute inset-0 w-screen h-screen bg-[#C71A17] overflow-hidden z-0">
      <img
        src="https://ttnzd.cn/static/v5/nxts-2022/nxts_srxx_bg_top.png"
        class="absolute inset-0 object-fill h-auto w-full z-0 opacity-100"
        alt=""
      />

      <img
        src="https://ttnzd.cn/static/v5/nxts-2022/nxts_srxx_bg_bottom.png"
        class="absolute bottom-0 left-0 object-fill h-auto w-full z-0 opacity-100"
        alt=""
      />
    </div>
    <!-- 提示向右滑动的动画 -->
    <div
      class="absolute top-1/2 left-0 z-[99] -translate-y-1/2 bg-red-600/20 w-full h-[4rem]"
      v-if="isArrow"
      @click="changeArrow()"
    >
      <img class="h-[4rem] runRight jiazai" src="../../assets/arrow_right.png" />
    </div>
    <!-- 屋子背景 -->
    <div
      class="swiperAnimation slidable absolute inset-0 mt-[4.5rem] short:mt-[2rem] mb-[4.9rem] overflow-hidden z-0 w-full h-[85vh]"
    >
      <img
        src="https://ttnzd.cn/static/v5/nxts-2022/long_bg_top.png"
        class="absolute inset-0 object-fill h-auto w-full z-0 opacity-100 px-[10px]"
        alt=""
      />
      <img
        src="https://ttnzd.cn/static/v5/nxts-2022/long_bg_middle.png"
        class="absolute top-[30%] object-fill h-2/4 w-full z-[-1] opacity-100 px-[10px]"
        alt=""
      />
      <img
        src="https://ttnzd.cn/static/v5/nxts-2022/long_bg_bottom.png"
        class="absolute bottom-0 left-0 object-fill h-auto w-full z-0 opacity-100 px-[10px]"
        alt=""
      />
      <div
        class="absolute short:top-[10rem] se:top-[8rem] long:top-[10rem] top-[11rem] w-full overflow-scroll h-[58vh]"
      >
        <div
          v-for="(taiSui, index) in taiSuis"
          v-if="index === currentTaiSui"
          class="font-bold text-lg fade-slide-item w-full h-full flex flex-col items-stretch px-6"
          :key="taiSui.sub_ID"
        >
          <template v-if="taisuiCard">
            <div class="fixed w-full px-[4rem] h-[50vh] left-1/2 -translate-x-1/2" @click="taisuiCard = !taisuiCard">
              <img
                class="w-full h-full"
                :src="'https://ttnzd.cn/static/v5/nxts-2022/tai-suis/' + taiSui.jia_zi + '太岁.jpg'"
              />
            </div>
          </template>
          <!-- 信息 -->
          <template v-else>
            <div class="flex flex-row items-center justify-center space-x-4">
              <img :src="taiSui.tai_sui.image" alt="" class="w-16 h-16 shadow-md rounded-full" />
              <div class="mr-[1.1rem] flex flex-col justify-center items-start">
                <div class="flex flex-row justify-center items-center text-[#D27402] text-[1.13rem]">
                  <div>姓名:{{ taiSui.nick_Name | onlyFirstLetter }}</div>
                  <div class="ml-[1rem]">生肖:{{ taiSui.sheng_xiao }}</div>
                </div>
                <div
                  class="text-[#000000]"
                  v-if="['害太岁', '刑太岁', '破太岁', '合太岁', '坐太岁', '冲太岁'].includes(taiSui.judgment) == true"
                >
                  糟糕程度:
                  <span class="text-[#B60001]">
                    {{ ['害太岁', '刑太岁', '破太岁'].includes(taiSui.judgment) == true ? '高' : '低' }}
                  </span>
                </div>
              </div>
            </div>
            <!-- 太岁 -->
            <div class="flex flex-row mx-auto space-x-[1rem] mt-[1rem] mb-[1rem]">
              <div
                v-for="(char, index) in taiSui.judgment"
                :key="index"
                class="font-bold short:text-[1.7rem] text-[2.3rem] leading-[2rem] bg-contain bg-center bg-no-repeat short:h-[2.7rem] short:w-[2.7rem] h-[3rem] w-[3rem] flex items-center justify-center"
                :style="'background-image: url(' + charBgImgs['bad'] + ');'"
              >
                {{ char }}
              </div>
            </div>

            <!-- 太岁牌 -->
            <img
              class="mx-[1.5rem] h-[6.5rem]"
              @click="taisuiCard = !taisuiCard"
              src="https://ttnzd.cn/static/v5/nxts-2022/taisui_banner.png"
            />
            <!-- 释义 -->
            <div class="mx-[1.6rem] mt-[0.5rem] text-[#333333] text-[0.88rem]">
              <span class="text-[#C71A17]">释义：</span>
              {{ taiSui.tai_sui.story }}
            </div>
            <!-- 解太岁 -->
            <div class="mx-[1.6rem] pb-[1rem] text-[#333333] text-[0.88rem]" v-if="taiSui.solution">
              <span class="text-[#C71A17]">解太岁：</span>
              <div v-for="(itm, idx) in getStr(taiSui.solution.Solution)" :key="idx">
                {{ itm }}
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <!-- <div class="absolute top-[14rem] short:top-[11rem] slidable w-full h-full overflow-hidden">
            <transition-group name="fade-slide" class="h-full w-full">
               
      
                
            </transition-group>
        </div> -->
  </div>
</template>

<script>
import TheBackground from '../../components/TheBackground'
import { random } from '../../api/taisui/taisui'
export default {
  name: 'Tiktok',
  components: {
    TheBackground,
  },
  data() {
    return {
      boardImg: 'https://ttnzd.cn/static/v5/nxts-2022/long_bg.png',
      showHanging: false,
      taiSuis: [],
      currentTaiSui: 0,
      charBgImgs: {
        good: 'https://ttnzd.cn/static/v5/nxts-2022/wufangchong_zi_bg.png',
        bad: 'https://ttnzd.cn/static/v5/nxts-2022/haitaisui_zi_bg.png',
      },
      taisuiCard: false,
      isArrow: false,
      movedBy: 0,
    }
  },

  filters: {
    onlyFirstLetter(str) {
      return str.substring(0, 1) + '*'
    },
  },
  mounted() {
    document.querySelector('#music').pause() // test

    this.initSlider()

    this.getRandomTaiSui()

    setTimeout(() => {
      this.isArrow = true
    }, 3000)
  },

  watch: {
    currentTaiSui(newVal, oldVal) {
      if (newVal === this.taiSuis.length - 3) {
        this.getRandomTaiSui()
      }
    },
  },

  methods: {
    toIndex() {
      this.$router.push('/')
    },

    getStr(str) {
      var s = str.replace('<br/>', '<br>')
      let newStr = []
      newStr = s.split('<br>')
      console.log(newStr)
      return newStr
    },
    changeArrow() {
      this.isArrow = false
    },
    async getRandomTaiSui() {
      let { data } = await random()
      if (this.taiSuis.length) {
        let old = this.taiSuis
        this.taiSuis = [...old, ...data.data]
      } else {
        this.taiSuis = data.data
      }
      console.log(this.taiSuis, '-------------')
    },

    initSlider() {
      const that = this
      const slidable = document.querySelector('.slidable')

      let isDragging = false,
        startPos = 0,
        currentTranslate = 0,
        prevTranslate = 0,
        animationID

      // touch events
      slidable.addEventListener('touchstart', touchStart)
      slidable.addEventListener('touchend', touchEnd)
      slidable.addEventListener('touchmove', touchMove)
      // mouse events
      slidable.addEventListener('mousedown', touchStart)
      slidable.addEventListener('mouseup', touchEnd)
      slidable.addEventListener('mousemove', touchMove)
      slidable.addEventListener('mouseleave', touchEnd)

      // prevent menu popup on long press
      window.oncontextmenu = function (event) {
        event.preventDefault()
        event.stopPropagation()
        return false
      }

      function getPositionX(event) {
        return event.type.includes('mouse') ? event.pageX : event.touches[0].clientX
      }

      function touchStart(event) {
        startPos = getPositionX(event)
        isDragging = true
        animationID = requestAnimationFrame(animation)
        slidable.classList.add('grabbing')
      }

      function touchMove(event) {
        if (isDragging) {
          const currentPos = getPositionX(event)
          currentTranslate = prevTranslate + currentPos - startPos
        }
      }

      function touchEnd() {
        cancelAnimationFrame(animationID)
        isDragging = false
        that.movedBy = currentTranslate - prevTranslate
        console.log(that.movedBy, '-----------------------')
        // if moved enough negative then snap to next slide if there is one
        if (that.movedBy < -70) {
          setPositionByIndex()
          // 还要初始化
          that.movedBy = 0
          startPos = 0
          currentTranslate = 0
        }
      }

      function animation() {
        setSliderPosition()
        if (isDragging) requestAnimationFrame(animation)
      }

      function setPositionByIndex() {
        const swiperAnimation = document.querySelector('.swiperAnimation')
        swiperAnimation.classList.add('swiper')
        that.isArrow = false
        setTimeout(() => {
          swiperAnimation.classList.remove('swiper')
          that.currentTaiSui += 1
        }, 1500)
        console.log('moving to: ' + that.currentTaiSui)
        // currentTranslate = currentIndex * -window.innerWidth
        // prevTranslate = currentTranslate
        // setSliderPosition()
      }

      function setSliderPosition() {
        // slidable.style.transform = `translateX(${currentTranslate}px)`
      }
    },
  },
}
</script>
<style scoped lang="scss">
.homeBg {
  background: url('https://ttnzd.cn/static/v5/nxts-2022/long_bg_middle.png');
  background-size: 100% 50%;
  background-position: center center;
}
.runRight {
  opacity: 0;
  animation: runRight 2s ease-in infinite;
}
.swiper {
  animation: swiperAnimation 1.5s ease-in 1;
}
@keyframes swiperAnimation {
  100% {
    transform: scale(0.1) translate(-100%, -100%);
    opacity: 0;
  }
}
@keyframes runRight {
  0% {
    transform: translateX(-8rem);
    opacity: 0;
  }
  40% {
    transform: translateX(6rem);
    opacity: 0.6;
  }
  60% {
    transform: translateX(18rem);
    opacity: 1;
  }

  100% {
    transform: translateX(20rem);
    opacity: 0;
  }
}
</style>
