<template>
    <div class="absolute inset-0 h-screen w-screen bg-black bg-opacity-80 z-[100]">
        <div class="h-full flex flex-col justify-between items-center">
            <div class="mt-[25vh] w-full flex flex-col justify-center items-center px-[3vw]">
                <!-- 字板 -->
                <div
                    class="bg-contain bg-no-repeat w-full box-border h-[87vw] flex flex-col items-stretch pt-[3rem] pb-[2.5rem] se:py-[2rem]"
                    style="background-image: url('https://ttnzd.cn/static/v5/nxts-2022/nxbz_text_bg_empty.png')"
                >
                    <!-- tab栏 -->
                    <div
                        class="flex flex-row items-center justify-center space-x-[4.19rem] se:space-x-[3rem] text-[1rem] font-medium leading-[1rem]"
                    >
                        <div
                            @click="type = 'FAQ'"
                            class="relative"
                            :class="[type === 'FAQ' ? 'down-caret text-[#fff9ee]' : 'text-[#FFE7B8]']"
                        >
                            常见问题
                        </div>
                        <div
                            @click="type = 'contact'"
                            class="relative"
                            :class="[type === 'contact' ? 'down-caret text-[#fff9ee]' : 'text-[#FFE7B8]']"
                        >
                            联系我们
                        </div>
                    </div>

                    <!-- 正文板 -->
                    <div
                        class="flex-grow mt-[1.33rem] bg-[#FFF9EE] mx-[2.25rem] rounded-lg px-[0.94rem] py-[0.91rem] se:py-[0.5rem] text-[#7E2007] font-medium leading-[1.2rem] text-[1rem] se:text-[0.8rem] flex flex-col justify-center"
                    >
                        <template v-if="type === 'FAQ'">
                            <div class="">1. 点击“开始”页面无响应</div>
                            <div class="mt-1">答：网络原因，请您稍等几秒或重新刷新页面；微信用户可关闭，重新打开</div>
                            <div class="mt-4">2. 页面出现图片无法显示</div>
                            <div class="mt-1">答：请在浏览器设置中关闭无图模式或者广告拦截</div>
                        </template>
                        <template v-if="type === 'contact'">
                            <div class="">我们的联系方式：</div>
                            <div class="mt-4 grid grid-cols-5 gap-x-1 gap-y-2">
                                <div class="col-span-2">服务热线：</div>
                                <div class="col-span-3">0755-25548001</div>
                                <div class="col-span-2">邮箱：</div>
                                <div class="col-span-3">feedback@jjim.org</div>
                                <div class="col-span-2">QQ账号：</div>
                                <div class="col-span-3">3023096804</div>
                                <div class="col-span-2">微信ID：</div>
                                <div class="col-span-3">search-for-self</div>
                            </div>
                            <div class="flex items-center justify-center text-gray-400 text-xs mt-3">
                                人体工程学工作室出品
                            </div>
                        </template>
                    </div>
                </div>

                <!-- 关闭按钮 -->
                <div class="w-full mt-[10vh] short:mt-[8vh] se:mt-[2vh] flex flex-row justify-center" @click="close()">
                    <div
                        class="bg-contain bg-no-repeat w-[1.69rem] h-[1.69rem]"
                        style="background-image: url('https://ttnzd.cn/static/v5/nxts-2022/close.png')"
                    ></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FAQ',
    methods: {},

    data() {
        return {
            type: 'FAQ',
        }
    },
    methods: {
        // 关闭按钮
        close() {
            this.$emit('helpClose')
        },
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.down-caret::after {
    position: absolute;
    content: '';
    border-top: 0.5rem solid #fff9ee;
    border-right: 0.29rem solid transparent;
    border-left: 0.29rem solid transparent;
    top: 1.4rem;
    left: 1.8rem;
}
</style>
