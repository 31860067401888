<template>
    <div class="w-screen h-screen bg-gray-50 flex items-center justify-center overflow-hidden">
        <div class="relative w-full h-full flex flex-col items-center justify-center overflow-hidden">
            <!-- 背景特效 -->
            <!-- 最上层 -->
            <div
                class="absolute top-[65vh] left-[5vw] w-12 h-12 bg-gradient-to-tr from-blue-500 to-transparent rounded-full bg-blend-multiply animate-blob-slow z-10 shadow-md"
            ></div>
            <div
                class="absolute top-[75vh] right-[25vw] w-16 h-16 bg-gradient-to-tr from-red-500 to-transparent rounded-full bg-blend-multiply animate-blob-slow z-10 animation-delay-2000 blur-sm shadow"
            ></div>
            <div
                class="absolute top-[80vh] right-[20vw] w-6 h-6 bg-gradient-to-tr from-green-500 to-transparent rounded-full bg-blend-multiply animate-blob-slow z-20 animation-delay-4000 shadow-md"
            ></div>
            <!-- 上层 -->
            <div
                class="absolute top-[3vh] right-[10vw] w-16 h-16 bg-gradient-to-tr from-blue-500 to-transparent rounded-full mix-blend-multiply blur-sm opacity-[0.85] animate-blob-fast animation-delay-2000"
            ></div>
            <div
                class="absolute top-[10vh] right-[5vw] w-12 h-12 bg-gradient-to-tr from-yellow-500 to-transparent rounded-full mix-blend-multiply blur-sm opacity-[0.85] animate-blob-exfast animation-delay-4000"
            ></div>

            <!-- 中层 -->
            <div
                class="absolute top-[3vh] right-0 w-24 h-24 bg-green-300/75 rounded-full mix-blend-multiply blur-md animate-blob"
            ></div>
            <div
                class="absolute top-[10vh] left-[5vw] w-28 h-28 bg-yellow-300/75 rounded-full mix-blend-multiply blur-md animate-blob animation-delay-4000"
            ></div>

            <!-- 最底层 -->
            <div
                class="absolute top-[40vh] left-0 w-60 h-60 bg-purple-300/75 rounded-full mix-blend-multiply blur-xl animate-blob"
            ></div>
            <div
                class="absolute top-[40vh] right-0 w-60 h-60 bg-yellow-300/75 rounded-full mix-blend-multiply blur-xl animate-blob animation-delay-2000"
            ></div>
            <div
                class="absolute top-[55vh] left-20 w-60 h-60 bg-pink-300/75 rounded-full mix-blend-multiply blur-xl animation-delay-4000 animate-blob"
            ></div>

            <div class="relative px-4 w-full -translate-y-4">
                <div class="bg-gray-300/50 px-3 pb-8 pt-6 rounded-3xl shadow-lg bg-blend-multiply">
                    <h3 class="text-2xl font-bold text-gray-800/50">活动页列表</h3>
                    <div class="mt-6 space-y-5 text-lg flex flex-col">
                        <router-link :to="{ name: 'test' }">
                            <div
                                id="nav"
                                class="bg-white rounded-xl py-4 px-6 shadow-md text-purple-800 font-thin relative flex flex-row justify-between items-center"
                            >
                                暖心太岁 2023
                                <div class="flex items-center space-x-2">
                                    <span class="text-xs tracking-wider">建设中</span>
                                    <span class="flex h-3 w-3 relative">
                                        <span
                                            class="animate-ping absolute inline-flex h-full w-full rounded-full bg-purple-400/75"
                                        ></span>
                                        <span class="relative inline-flex rounded-full h-3 w-3 bg-purple-500"></span>
                                    </span>
                                </div>
                            </div>
                        </router-link>
                        <router-link to="#">
                            <div id="nav" class="bg-gray-100 rounded-xl py-4 px-6 shadow-md text-gray-300">活动2</div>
                        </router-link>
                        <router-link to="#">
                            <div id="nav" class="bg-gray-100 rounded-xl py-4 px-6 shadow-md text-gray-300">活动3</div>
                        </router-link>
                    </div>
                </div>
            </div>

            <div class="absolute bottom-[3vh] w-full">
                <div class="flex justify-center">
                    <div class="mx-auto text-gray-500 font-light text-sm">人生北斗 · 天天你知道</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Home',
}
</script>
