<template>
    <div class="absolute w-full h-screen bg-black/70 z-[100]">
        <div class="w-full mt-[30%] se:mt-[0%] short:mt-[15%] main" :style="boardStyle">
            <div class="max-w-[15rem] flex flex-row justify-center items-center flex-wrap mx-auto space-x-[1.5rem]">
                <template v-if="maskType == 'age'">
                    <div
                        class="flex flex-col justify-start items-center special"
                        v-for="(item, index) in maskValue"
                        :key="index"
                        @click="getAge(item, index)"
                    >
                        <div class="text-[2rem] text-[#DA6F06] font-bold text-center">{{ item.txt }}</div>
                        <div class="text-[1rem] text-[#DA6F06] font-bold text-center">年代</div>
                    </div>
                </template>
                <template v-if="maskType == 'chineseZodiac'">
                    <div
                        class="flex flex-col justify-start items-center space-x-1 special"
                        v-for="(item, index) in maskValue"
                        :key="index"
                        @click="getChineseZodiac(item, index)"
                    >
                        <div
                            class="w-[2.5rem] h-[2.5rem]"
                            :style="item.isshow == 'true' ? 'opacity: 1.2;' : 'opacity: 0.2;'"
                        >
                            <img :src="`https://ttnzd.cn/static/v5/nxts-2022/${zodiacsImg[index]}.png`" />
                        </div>
                        <div
                            class="text-[1rem] text-[#b39f8b] font-bold text-center"
                            :style="item.isshow == 'true' ? 'opacity: 1;' : 'opacity: 0.5;'"
                        >
                            {{ item.txt }}
                        </div>
                    </div>
                </template>
            </div>
        </div>
        <div class="w-[2rem] h-[2rem] se:my-[-3rem] mx-auto close" @click="close()"></div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            zodiacsImg: ['鼠', '牛', '虎', '兔', '龙', '蛇', '马', '羊', '猴', '鸡', '狗', '猪'],
            boardStyle: {
                backgroundImage: '',
            },
        }
    },
    computed: {},
    props: {
        maskValue: {
            type: Array,
        },
        maskType: {
            type: String,
        },
    },
    mounted() {
        console.log(this.$props.maskType, this.$props.maskValue)

        if (this.$props.maskType == 'age') {
            this.boardStyle = {
                backgroundImage: `url('https://ttnzd.cn/static/v5/nxts-2022/age.png')`,
            }
        } else if (this.$props.maskType == 'chineseZodiac') {
            this.boardStyle = {
                backgroundImage: `url('https://ttnzd.cn/static/v5/nxts-2022/chineseZ.png')`,
                padding: '8rem 0 7rem 0',
            }
        }

        console.log(this.boardStyle)
    },
    methods: {
        close() {
            this.$emit('close')
        },
        getAge(item, index) {
            this.$emit('getAge', item, index)
        },
        getChineseZodiac(item, index) {
            this.$emit('getChineseZodiac', item, index)
        },
    },
}
</script>

<style lang="scss" scoped>
.main {
    padding: 8rem 0 9.5rem 0;
    background-size: 100% 100%;
}
.special:nth-child(4n + 1) {
    margin-left: 0 !important;
}
.close {
    background: url('https://ttnzd.cn/static/v5/nxts-2022/close.png') no-repeat;
    background-size: 100% 100%;
}
</style>
