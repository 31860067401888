<template>
    <div class="absolute w-full h-screen bg-black/80 z-[100]">
        <div class="mt-[50%] mx-[2rem] main">
            <div class="flex flex-col items-center justify-center">
                <div class="text-[1rem] text-[#310000] font-bold">{{ hangingText[0] }}</div>
                <div class="mx-[2rem] mt-[0.88rem] mb-[1.66rem] text-[0.88rem] text-[#310000]">
                    {{ hangingText[1] }}
                </div>
                <div
                    class="px-[1.19rem] py-[0.2rem] bg-gradient-to-t from-[#E83818] to-[#FF8038] text-[#FFDAA4] text-[1rem] font-medium"
                    @click="closeTaiSuiExplained()"
                >
                    我知道了
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        // 挂耳文案
        hangingText: {
            type: Array,
        },
    },
    methods: {
        closeTaiSuiExplained() {
            this.$emit('closeTaiSuiExplained')
        },
    },
}
</script>

<style lang="scss" scoped>
.main {
    padding: 2rem 0 3rem 0;
    background: url('https://ttnzd.cn/static/v5/nxts-2022/jieshe.png') no-repeat;
    background-size: 100% 100%;
}
</style>
