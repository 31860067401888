<template>
    <div class="w-full h-full">
        <div class="absolute inset-0 w-screen h-screen bg-[#A80000] overflow-hidden z-0">
            <img
                src="https://ttnzd.cn/static/v5/nxts-2022/zhiwenbg_top.png"
                class="absolute inset-0 object-fill h-auto w-full z-0 opacity-100"
                alt=""
            />

            <img
                src="https://ttnzd.cn/static/v5/nxts-2022/zhiwen_yun.png"
                class="absolute bottom-0 left-0 object-fill h-auto w-full z-0 opacity-100"
                alt=""
            />
        </div>

        <div class="z-10 relative h-full w-full">
            <div
                class="absolute top-[5vw] right-[11.3vw] bg-contain bg-no-repeat w-[1.91rem] h-[1.91rem] z-40 animate-spin-2s"
            >
                <img src="https://ttnzd.cn/static/v5/nxts-2022/music.png" alt="" />
            </div>

            <div
                class="z-20 flex flex-col items-center pt-[20vh] text-3xl se:text-2xl tracking-[0.3rem] leading-snug font-bold text-[#FFE1AC]"
            >
                <div class="">验明身份</div>
                <div class="">可得化解</div>
            </div>

            <!-- 指纹区域 -->
            <div class="relative" @touchstart="showButton()" @touchend="clearLoop()">
            <div class="absolute top-[0] left-[50%] goLeft mt-[14vh] short:mt-[7vh] flex items-center justify-center z-[1] change">
                <div
                    class="h-[48vw] w-[48vw] bg-contain bg-no-repeat bg-center flex items-center justify-center"
                    style="background-image: url('https://ttnzd.cn/static/v5/nxts-2022/zhuwen1.png')"
                >
                    <div
                        class="h-[24vw] w-[24vw] bg-contain bg-no-repeat bg-center"
                        style="background-image: url('https://ttnzd.cn/static/v5/nxts-2022/zhiwen2.png')"
                    ></div>
                </div>
            </div>
            <!-- 指纹变化 -->
             <div class=" absolute top-[0] left-[50%] goLeft mt-[14vh] short:mt-[7vh] flex items-center justify-center">
                <div
                    class="h-[48vw] w-[48vw] bg-contain bg-no-repeat bg-center flex items-center justify-center"
                    style="background-image: url('https://ttnzd.cn/static/v5/nxts-2022/zhiwen_bgs.png')"
                >
                    <div
                        class="h-[24vw] w-[24vw] bg-contain bg-no-repeat bg-center"
                        style="background-image: url('https://ttnzd.cn/static/v5/nxts-2022/指纹.png')"
                    ></div>
                </div>
            </div>
            </div>

            <!-- 指纹提示 -->
            <div class="mt-[50vh] text-center text-lg se:text-base text-[#FFE7B8] font-thin">长按指纹3秒</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Fingerprint',
    data() {
        return {
            loop: null,
        };
    },
    methods:{
        showButton(){
            clearTimeout(this.loop);//再次清空定时器，防止重复注册定时器
            this.loop=setTimeout(()=>{
            console.log("长按了");
            this.$router.push('/tai-sui/tai-sui-solver')
            },2000);
        },
        clearLoop(e) {
	        clearTimeout(this.loop);
        }
    }
}
</script>

<style lang="scss" scoped>
.goLeft{
    transform: translateX(-50%);
}
</style>
