import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/tailwind.css'
import './assets/app.css'
import VueMeta from 'vue-meta'
import { wxShare } from './api/taisui/taisui'

Vue.use(VueMeta)
Vue.config.productionTip = false

import jweixin from 'jweixin-module'
Vue.prototype.$jweixin = jweixin //H
Vue.prototype.$wxShare = wxShare //H

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
