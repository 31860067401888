<template>
    <TheBackground :show-footer="showFooter" :board-img="boardImg" :max-board-height="90">
        <!-- 遮罩层 -->
        <template v-slot:masks>
            <Tiger v-if="tigerShown" @skip="skip()"></Tiger>
            <FAQ v-if="FAQShown" @helpClose="helpClose()"></FAQ>
        </template>

        <!-- 内容 -->
        <div class="relative flex flex-col items-center justify-start z-30">
            <div class="w-[8.16rem] h-[2.81rem]">
                <img src="https://ttnzd.cn/static/v5/nxts-2022/nxts_home_2022.png" alt="" />
            </div>

            <div
                class="mt-[2.495rem] short:mt-[1rem] flex flex-col items-center font-bold text-[1.56rem] text-[#FFDC98] leading-[2.34rem] translate-x-[0.5rem]"
            >
                <span class="">怎么就犯太岁了！</span>
                <span>谁来守护我？</span>
                <span>该怎么办？</span>
            </div>
            <!-- 开始 -->
            <div class="mt-[2.3rem] se:mt-[1.5rem] w-[12.13rem] h-[2.84rem]" @click="goUpPage()">
                <img src="https://ttnzd.cn/static/v5/nxts-2022/nxts_home_btn.png" alt="" />
            </div>

            <div class="mt-[0.53rem] text-[#AF2500] text-[0.88rem]" @click="FAQShown = true">-暖心帮助-</div>
        </div>
    </TheBackground>
</template>

<script>
import TheBackground from '../../components/TheBackground'
import Tiger from '../../components/masks/Tiger'
import FAQ from '../../components/masks/FAQ'
export default {
    name: 'Start',
    components: {
        TheBackground: TheBackground,
        Tiger: Tiger,
        FAQ: FAQ,
    },
    data() {
        return {
            tigerShown: true,
            FAQShown: false,
            showFooter: false,
            boardImg: 'https://ttnzd.cn/static/v5/nxts-2022/nxts_home_text_bg.png',
        }
    },

    created() {
        this.$wxShare('https://new-taisui.ttnzd.cn' + this.$route.path)
    },
    methods: {
        // 跳转页面
        goUpPage() {
            this.$router.push('/tai-sui/gatherInformation')
        },
        // 子页面跳过按钮
        skip() {
            this.tigerShown = false
            console.log('1111')
        },
        // 暖心帮助关闭按钮
        helpClose() {
            this.FAQShown = false
        },
    },
}
</script>

<style></style>
