<template>
    <div class="w-full h-full relative bg-[#C71A17]">
        <!-- 遮罩层 -->
        <slot name="masks"></slot>

        <div class="absolute inset-0 w-screen h-screen bg-[#C71A17] overflow-hidden z-0" id="app_2">
            <img
                src="https://ttnzd.cn/static/v5/nxts-2022/nxts_srxx_bg_top.png"
                class="absolute inset-0 object-fill h-auto w-full z-0 opacity-100"
                alt=""
            />

            <img
                src="https://ttnzd.cn/static/v5/nxts-2022/nxts_srxx_bg_bottom.png"
                class="absolute bottom-0 left-0 object-fill h-auto w-full z-0 opacity-100"
                alt=""
            />
        </div>

        <div class="z-10 relative h-full w-full">
            <!-- 右侧前景灯笼 -->
            <div class="absolute top-0 right-0 bg-contain bg-no-repeat w-[5.22rem] h-auto z-30">
                <img src="https://ttnzd.cn/static/v5/nxts-2022/nxts_srxx_denglong.png" alt="" />
            </div>

            <!-- 音乐按钮 -->
            <div
                class="absolute top-[5vw] right-[11.3vw] bg-contain bg-no-repeat w-[1.91rem] h-[1.91rem] z-40"
                @click="switchMute"
            >
                <img v-if="mute" src="https://ttnzd.cn/static/v5/nxts-2022/music_pase.png" alt="" />
                <img v-else src="https://ttnzd.cn/static/v5/nxts-2022/music.png" alt="" class="animate-spin-2s" />
            </div>

            <div class="px-[2.5vw] w-full h-full">
                <!-- 字板图 -->
                <slot name="override">
                    <div class="pt-[18vw] short:pt-[5vw] se:pt-[2vw] w-full h-[160vw]" :class="[boardHeightClass]">
                        <div
                            class="relative bg-center bg-no-repeat z-20 flex flex-col items-center justify-center h-full pt-[21vh] short:pt-[21vh] pb-[2vh] short:pb-[1.5vh] px-3"
                            style="background-size: 100% 100%"
                            :style="'background-image: url(' + boardImg + ')'"
                        >
                            <!-- 挂耳 -->
                            <div
                                v-if="showHanging"
                                class="absolute top-[16.2%] right-[8%] bg-contain bg-no-repeat w-[1.81rem] h-[4.19rem] z-40 origin-top animate-swing"
                                @click="openTaiSuiExplained()"
                            >
                                <img :src="hangingImg" alt="" />
                            </div>

                            <!-- 内容 -->
                            <slot></slot>
                        </div>
                    </div>
                </slot>

                <!-- footer -->
                <div
                    v-if="showFooter"
                    class="flex flex-col justify-center space-y-[0.5rem] long:space-y-[1rem] short:space-y-[0.3rem] se:space-y-[0.2rem] items-center min-h-[20%]"
                >
                    <!-- 人生北斗文字 -->
                    <div class="text-white text-[0.7rem] se:text-[0.5rem] text-center">
                        <div class="">人生北斗工作团队设计制作</div>
                        <div class="">人体工程学团队（深圳）出品</div>
                    </div>

                    <!-- 按钮 -->
                    <div class="flex items-center justify-between space-x-[0.44rem] flex-shrink-0">
                        <div
                            class="w-[10.08rem] se:w-[7.5rem] h-[2.75rem] se:h-[2.06rem] bg-cover bg-no-repeat flex-shrink-0 flex items-center justify-center font-medium text-[#C10D09] text-[1.06rem] leading-[1rem]"
                            style="background-image: url('https://ttnzd.cn/static/v5/nxts-2022/nxts_srxx_btn2.png')"
                            @click="goDownPage()"
                        >
                            {{ footerButtonsText['left'] }}
                        </div>
                        <div
                            class="w-[10.08rem] se:w-[7.5rem] h-[2.75rem] se:h-[2.06rem] bg-cover bg-no-repeat flex-shrink-0 flex items-center justify-center font-medium text-[#FFE4BA] text-[1.06rem] leading-[1rem]"
                            style="background-image: url('https://ttnzd.cn/static/v5/nxts-2022/nxts_srxx_btn.png')"
                            @click="goUpPage()"
                        >
                            {{ footerButtonsText['right'] }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TheBackground',
    props: {
        showFooter: {
            // 是否显示底部
            type: Boolean,
            default: false,
        },
        boardImg: String, // 字板图
        maxBoardHeight: {
            // 字板最大高度
            type: Number,
            default: 80,
        },
        footerButtonsText: {
            // 两个按钮的文字
            type: Object,
            default: () => {
                return {
                    left: '上一步',
                    right: '下一步',
                }
            },
        },
        showHanging: {
            // 是否显示挂耳
            type: Boolean,
            default: false,
        },
        hangingImg: {
            // 挂耳图
            type: String,
            default: 'https://ttnzd.cn/static/v5/nxts-2022/pojietaisui_icon.png',
        },
    },
    data() {
        return {}
    },

    computed: {
        boardHeightClass() {
            return 'max-h-[' + this.maxBoardHeight + '%] long:h-[' + this.maxBoardHeight + '%]'
        },

        mute() {
            return this.$store.state.mute
        },
    },
    methods: {
        // 上一页
        goDownPage() {
            this.$emit('goDownPage')
        },
        // 下一页
        goUpPage() {
            this.$emit('goUpPage')
        },
        openTaiSuiExplained() {
            this.$emit('openTaiSuiExplained')
        },
        switchMute() {
            // 切换静音
            this.$store.commit('switchMute')
        },
    },
}
</script>

<style scoped lang="scss"></style>
