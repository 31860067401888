import axios from 'axios'

// var newurl = 'https://dev.ttnzd.cn'
const baseURL = 'https://dev.ttnzd.cn'
// const baseURL = 'https://192.168.8.16/'
const request = axios.create({
    baseURL: baseURL,
    timeout: 12000,
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json, text/javascript',
    },
})

request.interceptors.request.use(
    (data) => {
        return data
    },
    (error) => {
        return Promise.reject(error)
    },
)
request.interceptors.response.use(
    (data) => {
        return data
    },
    (error) => {
        return Promise.reject(error)
    },
)

export default request
