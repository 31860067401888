<template>
    <div class="absolute inset-0 h-screen w-screen overflow-hidden bg-black/80 z-[100]">
        <div class="h-full flex flex-col justify-between items-center">
            <div class="mt-[6rem] short:mt-[5rem] se:mt-[2rem] w-full">
                <!-- 字板 -->
                <div class="relative max-w-full bg-contain bg-no-repeat w-[18.19rem] h-[10.56rem] mx-auto">
                    <img
                        class="absolute"
                        src="https://ttnzd.cn/static/v5/nxts-2022/nxts_jinniuduibai_zi_bg.png"
                        alt=""
                    />
                    <div
                        class="relative flex flex-col justify-center items-stretch px-[1rem] pt-[1.84rem] text-[0.94rem] leading-[1.41rem] text-[#A52000] font-medium whitespace-pre-wrap"
                        style="text-indent: 1.88rem"
                    >
                        <div v-for="sentence in taiSuiStatements[phase]" v-if="isShowWord">
                            <div>{{ sentence }}</div>
                        </div>
                    </div>
                </div>

                <!-- 按钮 -->
                <div
                    class="w-full mt-[1.59rem] se:mt-[1rem] flex flex-row justify-center space-x-[2.25rem]"
                    v-if="isShowWord"
                >
                    <template v-if="isphase == true">
                        <div class="relative w-[7.13rem] h-[2.47rem] bg-contain bg-no-repeat" @click="skip()">
                            <img
                                class="absolute"
                                src="https://ttnzd.cn/static/v5/nxts-2022/nxts_jinniuduibai_tiaoguo.png"
                                alt=""
                            />
                            <div
                                class="relative flex justify-center items-center h-full w-full text-[1.19rem] leading-[1.41rem] text-white font-medium"
                                style="text-shadow: 0 0.1rem 0 #c32200"
                            >
                                跳过
                            </div>
                        </div>

                        <div class="relative w-[7.13rem] h-[2.47rem] bg-contain bg-no-repeat" @click="nextWord()">
                            <img
                                class="absolute"
                                src="https://ttnzd.cn/static/v5/nxts-2022/nxts_jinniuduibai_tiaoguo.png"
                                alt=""
                            />
                            <div
                                class="relative flex justify-center items-center h-full w-full text-[1.19rem] leading-[1.41rem] text-[#ffd38d] font-medium"
                                style="text-shadow: 0 0.1rem 0 #c32200"
                            >
                                下一句
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <div class="relative w-[12.65rem] h-[2.47rem] bg-contain bg-no-repeat" @click="skip()">
                            <img class="absolute" src="https://ttnzd.cn/static/v5/nxts-2022/nxts_yhdb_btn.png" alt="" />
                            <div
                                class="relative flex justify-between items-center h-full w-full text-[1.19rem] leading-[1.41rem] font-medium pl-[0.95rem] pr-[0.75rem]"
                                style="text-shadow: 0 0.1rem 0 #c32200"
                            >
                                <div class="text-[#ffd38d]">点击进入</div>
                                <div class="text-[#FFF0EC]">暖心太岁</div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>

            <!-- 老虎云 -->
            <div class="bottom-0 relative">
                <!-- 前景云 -->
                <div class="relative w-full h-auto bg-contain bg-no-repeat z-[101] yun1">
                    <img src="https://ttnzd.cn/static/v5/nxts-2022/nxts_jinniuduibai_yun.png" alt="" />
                </div>
                <div class="absolute bottom-0 left-[-100%] w-full h-auto bg-contain bg-no-repeat z-[101] yun1">
                    <img src="https://ttnzd.cn/static/v5/nxts-2022/nxts_jinniuduibai_yun.png" alt="" />
                </div>
                <!-- 后边云 -->
                <div class="absolute w-full h-auto bg-contain bg-no-repeat z-[100] yun2">
                    <img src="https://ttnzd.cn/static/v5/nxts-2022/nxts_jinniuduibai_yun.png" alt="" />
                </div>
                <div class="absolute left-[-100%] w-full h-auto bg-contain bg-no-repeat z-[100] yun2">
                    <img src="https://ttnzd.cn/static/v5/nxts-2022/nxts_jinniuduibai_yun.png" alt="" />
                </div>
                <!-- 老虎 -->
                <div class="absolute w-full flex justify-center items-center z-[102] bottom-[1.88rem]">
                    <div
                        class="bg-contain bg-no-repeat w-[10rem] short:w-[9rem] se:w-[8rem] h-auto animate-slide-in-right"
                    >
                        <img src="https://ttnzd.cn/static/v5/nxts-2022/nxts_jinniuduibai_laohu.png" alt="" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Tiger',
    methods: {},

    data() {
        return {
            isShowWord: false,
            isphase: true,
            phase: 0,
            taiSuiStatements: [
                [
                    '嗷——萌兔冲天！2023年到了,知道吗？',
                    '可不是只有本命年的你才会犯太岁哦！',
                    '嗷——犯太岁的也不一定就倒霉！',
                ],
                ['嗷——嗷——木星绕日运行，进而产生与天地人之间的能量交互，影响运势的起落，这就是太岁的来源。'],
                [
                    '六十位大将军被封为太岁，每年有一位',
                    '大将军掌握人间祸福，被称为值年太岁。',
                    '你的本命太岁即你出生那年的值年太岁。',
                ],
                ['嗷——2023年是葵卯太岁值年~', '想了解2023年葵卯太岁对你新年运势的影响，就快点点击进入【暖心太岁】吧'],
            ],
        }
    },
    created() {
        setTimeout(() => {
            this.isShowWord = true
        }, 2100)
    },
    methods: {
        // 跳过按钮
        skip() {
            this.$emit('skip')
        },
        // 下一句按钮
        nextWord() {
            if (this.phase >= this.taiSuiStatements.length - 2) {
                this.isphase = false
                this.phase = this.taiSuiStatements.length - 1
            } else {
                this.phase++
            }
        },
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.yun1 {
    animation: ar_Right 8s 2s linear infinite;
}
@keyframes ar_Right {
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(100%);
    }
}
.yun2 {
    transform: scaleX(-1);
    bottom: 3rem;
    animation: ar_Left 8s 1s linear infinite;
}
@keyframes ar_Left {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0%);
    }
}
</style>
