<template>
    <TheBackground
        :show-footer="showFooter"
        :board-img="boardImg"
        :max-board-height="80"
        :footer-buttons-text="footerButtonsText"
        :show-hanging="showHanging"
        :hanging-img="showHanging ? hangingImgs[taiSuiDetails.luck ? 'good' : 'bad'] : ''"
        @goDownPage="goDownPage()"
        @goUpPage="goUpPage()"
        @openTaiSuiExplained="openTaiSuiExplained()"
    >
        <template v-slot:masks>
            <Share v-if="isShow" @close="close()" />
            <TaiSuiExplained
                v-if="isShowTaiSuiExplained"
                :hangingText="hangingText"
                @closeTaiSuiExplained="closeTaiSuiExplained()"
            />
        </template>
        <div v-if="taiSui" class="flex flex-col items-center justify-start z-30 w-full h-full">
            <div
                class="flex flex-col items-center justify-start w-full overflow-y-scroll flex-grow"
                :class="taiSuiDetails.luck ? 'text-[#CB1A1B]' : 'text-gray-900'"
            >
                <h6 class="text-[1.13rem] leading-[1.13rem] font-bold">{{ taiSui.user[0].nick_Name }} · 2023</h6>

                <div class="flex flex-col items-center justify-start">
                    <!-- 太岁解释标题 -->
                    <div class="flex flex-row w-full mt-8 px-6 items-center justify-center space-x-5">
                        <div
                            v-for="(char, index) in taiSui.kam[0].Solution"
                            :key="index"
                            class="font-bold text-[2.3rem] leading-[2rem] bg-contain bg-center bg-no-repeat h-[3.5rem] w-[3.5rem] flex items-center justify-center"
                            :style="'background-image: url(' + charBgImgs[taiSuiDetails.luck ? 'good' : 'bad'] + ');'"
                        >
                            {{ char }}
                        </div>
                    </div>

                    <!-- 太岁程度 -->
                    <div v-if="!taiSuiDetails.luck" class="mt-1">
                        *糟糕程度：
                        <span class="text-[#B60001]">{{ taiSuiDetails.level }}</span>
                    </div>
                </div>

                <!-- 释义 -->
                <div class="mt-6 flex flex-col items-center">
                    <div class="font-bold text-[1.13rem] leading-[1.13rem]">释义</div>
                    <div class="mt-2 mx-4 px-4 text-gray-800">{{ taiSui.harm }}</div>
                </div>

                <!-- 印章 -->
                <div class="flex flex-col items-center justify-center w-[100%] long:mt-[2.5rem]">
                    <div class="flex row items-center justify-center space-x-3">
                        <div
                            :class="'w-[18%] item' + [index]"
                            v-for="(item, index) in taiSuiDetails.report"
                            :key="index"
                        >
                            <img :src="item.Solution" />
                        </div>
                    </div>
                    <img class="w-[18%] mt-[0.5rem] item5" :src="taiSui.report[4].Solution" />
                </div>
            </div>
        </div>
    </TheBackground>
</template>

<script>
import TheBackground from '../../components/TheBackground'
import Share from '../../components/masks/Share'
import TaiSuiExplained from '../../components/masks/TaiSuiExplained'
import { detail } from '../../api/taisui/taisui'
export default {
    name: 'TaiSuiDetails',
    components: {
        TheBackground: TheBackground,
        Share,
        TaiSuiExplained,
    },
    created() {
        this.getDetail()
        this.$wxShare('https://new-taisui.ttnzd.cn' + this.$route.path)
    },
    methods: {
        // 跳转页面
        goDownPage() {
            if (this.taiSuiDetails.luck) {
                this.isShow = true
            } else {
                this.$router.push('/tai-sui/tai-sui')
            }
        },
        goUpPage() {
            if (this.taiSuiDetails.luck) {
                this.$router.push('/tai-sui/tiktok')
            } else {
                this.$router.push('/tai-sui/fingerprint')
            }
        },
        close() {
            this.isShow = false
        },
        openTaiSuiExplained() {
            this.isShowTaiSuiExplained = true
            console.log('00000000000000')
        },
        closeTaiSuiExplained() {
            this.isShowTaiSuiExplained = false
        },
        getDetail: async function () {
            let url = this.$store.state.user.client_ID
            let data = this.$store.state.user
            let res = (await detail(url, data).then()).data.data
            this.taiSui = res

            this.taiSuiDetails.luck = ['害太岁', '刑太岁', '破太岁', '合太岁', '坐太岁', '冲太岁'].includes(
                res.kam[0].Solution,
            )
                ? false
                : true
            this.taiSuiDetails.level = ['害太岁', '刑太岁', '破太岁'].includes(res.kam[0].Solution) ? '高' : '低'
            if (this.taiSuiDetails.luck) {
                this.footerButtonsText.left = '炫太岁'
                this.footerButtonsText.right = '查别人'
            } else {
                this.footerButtonsText.left = '上一步'
                this.footerButtonsText.right = '下一步'
            }
            this.hangingText[0] = res.kam[0].Solution
            this.hangingText[1] = res.desp
            this.taiSuiDetails.report = res.report.slice(0, 4)
        },
    },
    computed: {
        showHanging() {
            return Boolean(this.taiSuiDetails)
        },
        titleInArray() {
            return this.taiSuiDetails.title.split('')
        },
    },
    data() {
        return {
            // 控制数据
            boardImg: 'https://ttnzd.cn/static/v5/nxts-2022/wufangchuong_bg.png',
            taiSui: null,
            hangingImgs: {
                good: 'https://ttnzd.cn/static/v5/nxts-2022/wufangchong_icon.png',
                bad: 'https://ttnzd.cn/static/v5/nxts-2022/haitaisui_icon.png',
            },
            hangingText: [],
            charBgImgs: {
                good: 'https://ttnzd.cn/static/v5/nxts-2022/wufangchong_zi_bg.png',
                bad: 'https://ttnzd.cn/static/v5/nxts-2022/haitaisui_zi_bg.png',
            },
            showFooter: true,
            isShow: false,
            isShowTaiSuiExplained: false,
            footerButtonsText: {
                left: '上一步',
                right: '下一步',
            },
            taiSuiDetails: {
                level: '低',
                luck: true,
                report: null,
            },
        }
    },
}
</script>

<style scoped lang="scss">
.bgItem {
    background-size: 100% 100%;
}
.item1 {
    opacity: 0;
    animation: showYz 0.2s 1s 1 forwards;
}
.item2 {
    opacity: 0;
    animation: showYz 0.2s 1.5s 1 forwards;
}
.item3 {
    opacity: 0;
    animation: showYz 0.2s 2s 1 forwards;
}
.item4 {
    opacity: 0;
    animation: showYz 0.2s 2.5s 1 forwards;
}
.item5 {
    opacity: 0;
    animation: showYz 0.2s 3s 1 forwards;
}
@keyframes showYz {
    99% {
        opacity: 1;
        transform: translate3d(0, 0, 8rem) scale(3);
    }
    100% {
        opacity: 1;
        transform: translate3d(0, 0, 0rem) scale(1);
    }
}
</style>
