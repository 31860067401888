import request from '../request'
import jweixin from 'jweixin-module'

// var url = newurl + '/api/taisui/nuanxin/userinfo'
export function submitNews(param) {
    // let param = new URLsearchParams
    return request.post('/api/taisui/nuanxin/userinfo', param)
}

// https://dev.ttnzd.cn/api/taisui/nuanxin/meaning/
export function meaning(url, data) {
    return request.post('/api/taisui/nuanxin/meaning/' + url, data)
}

// https://dev.ttnzd.cn/api/taisui/nuanxin/detail/d5f73f59d1d74ecfa1a20d83fc50f883
export function detail(url, data) {
    return request.post('/api/taisui/nuanxin/detail/' + url, data)
}

// https://dev.ttnzd.cn/api/taisui/nuanxin/solution/d5f73f59d1d74ecfa1a20d83fc50f883
export function solution(url, sub_ID) {
    return request.post('/api/taisui/nuanxin/solution/' + url, { sub_ID: sub_ID })
}

export function random() {
    return request.get('/api/taisui/nuanxin/random')
}

export function wxShare(url) {
    let that = this
    // 分享到微信js-sdk
    let body = {
        api_list: ['updateAppMessageShareData', 'updateTimelineShareData'],
        url,
        debug: false,
    }

    request.post('/api/wechat/jsconfig', body).then(({ data: res }) => {
        console.log('res:', res.data)
        jweixin.config({
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: res.data.appId, // 必填，公众号的唯一标识
            timestamp: res.data.timestamp, // 必填，生成签名的时间戳
            nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
            signature: res.data.signature, // 必填，签名
            jsApiList: res.data.jsApiList, // 必填，需要使用的JS接口列表
        })

        jweixin.ready(function () {
            // 配置分享给朋友
            jweixin.updateAppMessageShareData({
                title: '暖心太岁-2023', // 分享标题
                desc: '2023，看看你犯太岁吗？', // 分享描述
                link: 'https://new-taisui.ttnzd.cn/tai-sui/start', // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: 'https://ttnzd.cn/static/v5/shareimg.jpg', // 分享图标
                success: function () {
                    // 设置成功
                    console.log(456)
                },
            })

            // 配置分享到朋友圈
            jweixin.updateTimelineShareData({
                title: '暖心太岁-2023', // 分享标题
                desc: '2023，看看你犯太岁吗？', // 分享描述
                link: 'https://new-taisui.ttnzd.cn/tai-sui/start', // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: 'https://ttnzd.cn/static/v5/shareimg.jpg', // 分享图标
                success: function () {
                    // 设置成功
                    console.log(456)
                },
            })
        })
    })
}
