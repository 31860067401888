import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: '',
    nick_Name: '',
    age: '我的出生',
    zodiac: '我的生肖',
    birth_Year: '',
    mute: false,
    isH5InWeapp: false,
  },
  mutations: {
    userAll(state, data) {
      state.user = data
    },
    nick_NameAll(state, data) {
      state.nick_Name = data
    },
    ageAll(state, data) {
      state.age = data
    },
    zodiacAll(state, data) {
      state.zodiac = data
    },
    birth_YearAll(state, data) {
      state.birth_Year = data
    },
    switchMute(state) {
      state.mute = !state.mute
    },
  },
  actions: {},
  modules: {},
})
