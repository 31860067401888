<template>
  <div class="absolute w-full h-screen bg-black/40 z-[100] flex flex-col justify-center items-center">
        <div v-if="showText==''" type="button" class="bg-[#f43f5e] flex flex-row justify-center items-center py-[1rem] px-[0.5rem] rounded-[13px] text-[#FFFFFF]">
            <img src="../../assets/showtoast.png" class="animate-spin h-6 w-6 mr-2 ..." />
            好运正在赶来的路上
        </div>
        <div v-else type="button" class="bg-[#f43f5e] flex flex-row justify-center items-center py-[0.7rem] px-[1rem] rounded-[13px] text-[#FFFFFF]">
            <!-- <img src="../../assets/showtoast.png" class="animate-spin h-6 w-6 mr-2 ..." /> -->
            {{showText}}
        </div>
  </div>
</template>

<script>
export default {
    props:{
        showText:{
            type:String
        }
    }
}
</script>

<style>

</style>