<template>
    <div class="slider-container" style="transform: translateX(0px)">
        <div class="slide rounded-3xl bg-blue-100 snap-center">1</div>
        <div class="slide rounded-3xl bg-red-100 snap-center">2</div>
        <div class="slide rounded-3xl bg-yellow-100 snap-center">3</div>
    </div>
    <!-- <div class="overflow-x-scroll gap-8 inline-flex items-stretch h-screen py-6 snap-mandatory snap-x">
        <div class="slide rounded-3xl bg-blue-100 snap-center">1</div>
        <div class="slide rounded-3xl bg-red-100 snap-center">2</div>
        <div class="slide rounded-3xl bg-yellow-100 snap-center">3</div>
    </div> -->
</template>

<script>
export default {
    name: 'Test',
    mounted() {
        document.querySelector('#music').pause()

        // 初始化滑动
        this.initSlider()
    },

    methods: {
        initSlider() {
            const slider = document.querySelector('.slider-container'),
                slides = Array.from(document.querySelectorAll('.slide'))

            let isDragging = false,
                startPos = 0,
                currentTranslate = 0,
                prevTranslate = 0,
                animationID,
                currentIndex = 0

            slides.forEach((slide, index) => {
                // touch events
                slide.addEventListener('touchstart', touchStart(index))
                slide.addEventListener('touchend', touchEnd)
                slide.addEventListener('touchmove', touchMove)
                // mouse events
                slide.addEventListener('mousedown', touchStart(index))
                slide.addEventListener('mouseup', touchEnd)
                slide.addEventListener('mousemove', touchMove)
                slide.addEventListener('mouseleave', touchEnd)
            })

            // make responsive to viewport changes
            window.addEventListener('resize', setPositionByIndex)

            // prevent menu popup on long press
            window.oncontextmenu = function (event) {
                event.preventDefault()
                event.stopPropagation()
                return false
            }

            function getPositionX(event) {
                return event.type.includes('mouse') ? event.pageX : event.touches[0].clientX
            }

            function touchStart(index) {
                return function (event) {
                    currentIndex = index
                    startPos = getPositionX(event)
                    isDragging = true
                    animationID = requestAnimationFrame(animation)
                    slider.classList.add('grabbing')
                }
            }

            function touchMove(event) {
                if (isDragging) {
                    const currentPosition = getPositionX(event)
                    currentTranslate = prevTranslate + currentPosition - startPos
                }
            }

            function touchEnd() {
                cancelAnimationFrame(animationID)
                isDragging = false
                const movedBy = currentTranslate - prevTranslate

                // if moved enough negative then snap to next slide if there is one
                if (movedBy < -100 && currentIndex < slides.length - 1) currentIndex += 1

                // if moved enough positive then snap to previous slide if there is one
                if (movedBy > 100 && currentIndex > 0) currentIndex -= 1

                setPositionByIndex()

                slider.classList.remove('grabbing')
            }

            function animation() {
                setSliderPosition()
                if (isDragging) requestAnimationFrame(animation)
            }

            function setPositionByIndex() {
                currentTranslate = currentIndex * -window.innerWidth
                prevTranslate = currentTranslate
                setSliderPosition()
            }

            function setSliderPosition() {
                slider.style.transform = `translateX(${currentTranslate}px)`
            }
        },
    },
}
</script>

<style>
:root {
    --shadow: rgba(0, 0, 0, 0.8);
}

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

html,
body {
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.slider-container {
    height: 100vh;
    display: inline-flex;
    overflow: hidden;
    scrollbar-width: none;
    transform: translateX(0);
    will-change: transform;
    transition: transform 0.3s ease-out;
    cursor: grab;
}

.slide {
    max-height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    max-width: 100%;
    transition: transform 0.3s ease-in-out;
    border-radius: 4px;
    user-select: none;
}

@media (min-width: 1200px) {
    .slide {
        padding: 3rem;
    }
}

.grabbing {
    cursor: grabbing;
}

.grabbing .slide {
    transform: scale(0.9);
    box-shadow: 5px 5px 40px -1px var(--shadow);
}
</style>
